import React from "react"

const Stars = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="62.026"
        height="62.029"
        viewBox="0 0 62.026 62.029"
      >
        <path
          fill="#fff"
          d="M484.112 461.423l-1.178 2.221 8.623.809-8.623.807 1.178 2.222-2.221-1.178-.809 8.622-.808-8.622-2.221 1.178 1.177-2.221-8.622-.808 8.622-.808-1.177-2.222 2.221 1.177.808-8.622.808 8.622 2.222-1.177zm-14.6-26.48l-3.485 6.578 25.528 2.393-25.527 2.386 3.486 6.58-6.576-3.485-2.4 25.529-2.392-25.53-6.577 3.486 3.485-6.576-25.528-2.394 25.529-2.392-3.486-6.58 6.575 3.486 2.393-25.53 2.393 25.53 6.579-3.486zm-26.479-14.6l-1.177 2.221 8.622.808-8.623.807 1.178 2.222-2.221-1.177-.812 8.625-.807-8.622-2.221 1.178 1.177-2.221-8.622-.808 8.622-.807-1.177-2.222 2.221 1.177.807-8.624.809 8.623 2.221-1.177zm41.077 0l-1.178 2.221 8.623.808-8.623.807 1.178 2.222-2.221-1.177-.809 8.622-.808-8.622-2.221 1.178 1.177-2.221-8.622-.808 8.622-.807-1.177-2.222 2.221 1.177.808-8.622.808 8.623 2.222-1.177zm-41.077 41.078l-1.177 2.221 8.622.809-8.623.807 1.178 2.222-2.221-1.178-.812 8.626-.807-8.622-2.221 1.178 1.177-2.221-8.622-.808 8.622-.808-1.177-2.222 2.228 1.175.808-8.622.809 8.622 2.221-1.177z"
          data-name="Path 17959"
          transform="translate(-429.531 -412.899)"
        ></path>
      </svg>
    </div>
  )
}

export default Stars
