import React from "react"

const Cross = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="258.285"
        height="226.928"
        viewBox="0 0 258.285 226.928"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M9.3 242.812c2.313 2.15 4.548 5.922 4.1 9.136 5.8-1.11 13.762 5.03 8.973 12.559-4.347-2.693-8 .07-8.763 3.128s-.621 20.957-.621 20.957 17.752.272 20.817-.481 5.821-4.424 3.128-8.771c7.529-4.781 13.669 3.175 12.559 8.973 4.913-.691 11.231 4.929 10.836 7.451.4 2.53-5.922 8.15-10.836 7.459 1.11 5.8-5.03 13.754-12.559 8.973 2.693-4.354-.07-8.01-3.128-8.771s-20.957-.621-20.957-.621-.279 68.359.481 71.425 4.417 5.821 8.763 3.128c4.789 7.521-3.175 13.661-8.965 12.559.691 4.913-4.929 11.232-7.459 10.836-2.523.4-8.142-5.922-7.451-10.836-5.8 1.1-13.762-5.037-8.973-12.559 4.347 2.693 8.01-.07 8.763-3.128s.621-71.557.621-71.557-17.751-.279-20.817.481-5.821 4.417-3.128 8.763c-7.521 4.789-13.661-3.175-12.559-8.973-4.913.691-11.232-4.929-10.836-7.451-.4-2.53 5.922-8.142 10.836-7.451-1.1-5.8 5.037-13.762 12.559-8.973-2.693 4.347.07 8 3.128 8.763s20.957.621 20.957.621.279-17.759-.481-20.817-4.417-5.821-8.763-3.128c-4.789-7.529 3.175-13.669 8.973-12.559-.4-2.833 1.3-6.085 3.291-8.3-44.015-57.762-125.689-20.171-125.689 48.343 0 47.588 42.993 81.523 75.329 107.106 33.62 26.593 43.051 32.282 53.817 43.048C16 431.379 25 425.115 59.051 399.1c32.81-25.055 75.337-60.046 75.337-107.619 0-67.701-80.925-105.281-125.088-48.669z"
          data-name="Path 18867"
          transform="translate(123.897 -215.217)"
        ></path>
      </svg>
    </div>
  )
}

export default Cross
